import React, { useState } from 'react'
import Layout from '../components/common/Layout'
import Fade from 'react-reveal/Fade'
import PageHeader from '../components/about/PageHeader'

const AboutPage = () => {
  return (
    <Layout
      meta={{
        description:
          'A story about how and why we built Atlas. Learn more about the team behind Atlas. We are a small team of passionate developers and designers.',
        title: 'Why we built Atlas - About',
        type: 'website'
      }}
      title={'Why we built Atlas - About'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader />
        </Fade>
      </main>
    </Layout>
  )
}

export default AboutPage
