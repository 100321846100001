import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mantine/core'
import styles from './PageHeader.module.scss'
import Button from '../common/Button'

const PageHeader = () => {
  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <h1>We're on a mission</h1>
            <p>
              We believe in a world where everyone is powered by sustainable
              energy. To reach this bold aim we enable energy developers to make
              data-driven decisions in an early screening phase of renewable
              energy projects
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
